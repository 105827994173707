import { api } from ":mods";
import { CONST } from "..";

export async function detailsDelete(ids: number[]): Promise<{ id: number; index: number; error?: string }[]> {
  return new Promise(async (res, rej) => {
    const result = [] as { id: number; index: number; error?: string }[];
    for (const id of ids) {
      const index = ids.indexOf(id);
      // result.push({ id, index });
      await api
        .delAuth(CONST.ENDPOINTS.admin.detail_resource(id))
        .then((res) => {
          result.push({ id, index });
        })
        .catch((err) => {
          result.push({ id, index, error: err.response.data.error });
        });
    }
    res(result);
  });
}
